<template>
  <div class="updatePasswordBox">
    <NavBar :title="userInfo.isSetPassWord ? '修改密码' : '设置密码'"></NavBar>
    <div class="contentBox">
      <template v-if="userInfo.isSetPassWord">
        <van-field
          v-model="oldPassword"
          label="旧密码"
          type="password"
          :label-width="parseInt($pxToPxRatio(130), 10)"
          placeholder="请输入旧的登录密码"
          :border="false"
        ></van-field>
        <van-divider />
      </template>
      <van-field
        v-model="password"
        label="新密码"
        type="password"
        :label-width="parseInt($pxToPxRatio(130), 10)"
        placeholder="请输入新密码（6至20位数字或字母）"
        :border="false"
      ></van-field>
      <van-divider />
      <van-field
        v-model="passwordAgain"
        label="确认密码"
        type="password"
        :label-width="parseInt($pxToPxRatio(130), 10)"
        placeholder="请再次输入新密码"
        :border="false"
      ></van-field>
      <van-divider />
      <Space :height="60"></Space>
      <TextBox color="intro1" :font-size="24" :bold="5">注意事项：</TextBox>
      <Space :height="10"></Space>
      <TextBox color="intro1" :font-size="24">·设置密码成功后将退出登录，需用新密码验证登录。 </TextBox>
      <Space :height="40"></Space>
    </div>
    <van-button class="fixedBottom" type="primary" block @click="submit" :loading="$store.state.submitLoading"
      >提交</van-button
    >
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      oldPassword: '',
      password: '',
      passwordAgain: '',
    };
  },
  // created () {},
  // mounted() {},
  methods: {
    submit: _.debounce(function() {
      if (this.userInfo.isSetPassWord) {
        if (!this.oldPassword) {
          this.$toast('旧密码不能为空');
          return;
        }
      }
      if (!this.password) {
        this.$toast('新密码不能为空');
        return;
      }
      if (this.password !== this.passwordAgain) {
        this.$toast('输入的两次新密码不一致');
        return;
      }
      let url = '';
      let params = '';
      if (this.userInfo.isSetPassWord) {
        // 修改密码
        url = '/v1/ModifyUser/ModifyPassword';
        params = {
          oldPassword: this.oldPassword,
          password: this.password,
          validatePassword: this.passwordAgain,
        };
      } else {
        // 设置密码
        url = '/v1/ModifyUser/setPassword';
        params = {
          password: this.password,
          validatePassword: this.passwordAgain,
        };
      }
      this.$store.commit('setSubmitLoading', true);
      this.$http('post', url, params)
        .then(res => {
          let that = this;
          this.$logout();
          this.$toast('修改成功');
          that.$router.replace('/account/login');
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
  },
  // watch: {},
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.updatePasswordBox {
  /deep/ .van-field {
    input {
      color: #2c395b;
    }
    .van-field__label {
      color: #2c395b;
    }
  }
}
</style>
